import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import BEMHelper from "react-bem-helper";
import Helmet from "react-helmet";

import SwiftType from "../components/swift-type";

// Components
import Layout from "../components/layout";
import SEO from "../components/seo";

import ModuleRelatedContent from "../modules/module-related-content";

// Helpers, Functions
import { richTextConfig } from "../rich-text-config/rich-text-config";

const bem = new BEMHelper({
  name: "template-thanks",
});

const TemplateThanks = ({ data, location, pageContext }) => {
  const {
    publishDate,
    insightType,
    insightTitle,
    seoPageTitle,
    headerIntro,
    mainImage,
    relatedSolution,
    addKeyResults,
    addVideo,
    addForm,
    attachFileDownload,
    previewExcerpt,
    swiftDate,
  } = data.contentfulTemplateInsight;

  const whitePapers = pageContext.relatedWhitePapers;

  let solutionTitle = null;
  let solutionSlug = null;
  let solutionImg = null;

  if (relatedSolution) {
    if (relatedSolution?.pageTitle) {
      solutionTitle = relatedSolution?.pageTitle;
    }
    if (relatedSolution?.slug) {
      solutionSlug = `/solutions/${relatedSolution?.slug}`;
    }
  }

  const insightSolution = () => {
    if (relatedSolution?.pageTitle.includes("Contracting")) {
      return "Contracting";
    } else if (relatedSolution?.pageTitle.includes("Operations")) {
      return "Operations, Risk and Compliance";
    } else if (relatedSolution?.pageTitle.includes("Litigation")) {
      return "Litigation and Investigations";
    } else return relatedSolution?.pageTitle;
  };

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);
  const bodyJson = data?.contentfulTemplateInsight?.body ? JSON.parse(data?.contentfulTemplateInsight?.body?.raw) : null;
  const swiftBody =
  bodyJson ? bodyJson.content[0].content[0]?.value : null;

  const authors = data.contentfulTemplateInsight.addSpeakersOrAuthors
    ? data.contentfulTemplateInsight.addSpeakersOrAuthors
    : null;

  const metaTitle = seoPageTitle || `${insightTitle} | UnitedLex`;
  return (
    <Layout
      isWhiteNav={insightType?.insightType === "White Paper" ? true : false}
      location={location}
    >
      <SEO
        title={metaTitle}
        description={previewExcerpt?.previewExcerpt}
        image={mainImage?.file?.url}
        url={location?.href}
      />
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div {...bem()}>
        <div {...bem("whitepaper-copy")}>
          {addForm &&
            addForm.successMessage &&
            documentToReactComponents(
              JSON.parse(addForm.successMessage.raw),
              richTextConfig()
            )}
          {attachFileDownload && (
            <a
              href={`https:${attachFileDownload.file.url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="hubspot-form__download-button"
            >
              Download White Paper
            </a>
          )}
        </div>
      </div>

      {whitePapers && (
        <ModuleRelatedContent
          relatedInsights={whitePapers.slice(0,4)}
          moduleHeadline="Related White Papers"
          selectContent="Related Insights"
        />
      )}
    </Layout>
  );
};

export default TemplateThanks;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTemplateInsight(slug: { eq: $slug }) {
      previewExcerpt {
        previewExcerpt
      }
      insightTitle
      seoPageTitle
      slug
      doNotIndex
      headerIntro {
        headerIntro
      }
      mainImage {
        description
        gatsbyImageData(width: 1000, formats: JPG, quality: 80)
        # fluid(maxWidth: 1000, toFormat: JPG, quality: 80) {
        #  ...GatsbyContentfulFluid
        # }
        file {
          url
        }
      }
      publishDate(formatString: "MMMM D, YYYY")
      swiftDate: publishDate(formatString: "YYYY.MM.DD")
      insightType {
        insightType
      }
      relatedSolution {
        slug
        pageTitle
        header {
          ... on Node {
            ... on ContentfulModuleHeaderWithMedia {
              __typename
              headerImage {
                description
                gatsbyImageData(
                  formats: JPG
                  width: 120
                  height: 200
                  cropFocus: CENTER
                  resizingBehavior: FILL
                )
                # fixed(
                #  toFormat: JPG
                #  width: 120
                #  height: 200
                #  cropFocus: CENTER
                #  resizingBehavior: FILL
                # ) {
                #  ...GatsbyContentfulFixed
                # }
              }
            }
          }
        }
      }
      body {
        raw
      }
      addKeyResults {
        bigText
        littleText {
          littleText
        }
      }
      addSpeakersOrAuthors {
        ... on Node {
          ... on ContentfulGlobalPeople {
            __typename
            contentful_id
            name
            jobTitle {
              jobTitle
            }
            image {
              gatsbyImageData(width: 300, formats: JPG)
              # fluid(toFormat: JPG, maxWidth: 300) {
              #  ...GatsbyContentfulFluid
              # }
            }
          }
          ... on Node {
            ... on ContentfulTemplateLeadership {
              __typename
              contentful_id
              name: fullName
              slug
              jobTitle {
                raw
              }
              image: picture {
                gatsbyImageData(width: 300, formats: JPG)
                # fluid(toFormat: JPG, maxWidth: 300) {
                #  ...GatsbyContentfulFluid
                # }
              }
            }
          }
        }
      }
      addVideo {
        ...ContentfulItemMediaCardFragment
      }
      addForm {
        contentful_id
        formId
        formHeader {
          raw
        }
        successMessage {
          raw
        }
      }
      attachFileDownload {
        file {
          url
        }
      }
    }
    #     relatedWhitePapers: allContentfulTemplateInsight(
    #      filter: {
    #        insightTitle: { ne: null }
    #        insightType: { insightType: { eq: "White Paper" } }
    #        relatedSolution: { pageTitle: { eq: $solution } }
    #      }
    #      sort: { order: DESC, fields: publishDate }
    #      limit: 3
    #    ) {
    #      nodes {
    #        __typename
    #        contentful_id
    #        insightTitle
    #        slug
    #        previewExcerpt {
    #          previewExcerpt
    #        }
    #        mainImage {
    #          description
    #          fluid(maxWidth: 800, toFormat: JPG) {
    #            ...GatsbyContentfulFluid
    #          }
    #          file {
    #            url
    #          }
    #       }
    #        insightType {
    #          insightType
    #       }
    #        publishDate
    #     }
    #    }
  }
`;
